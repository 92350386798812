import React, { useState } from 'react';
import { Box, TextField, Button, Link, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginWrapper = styled(Paper)`
  padding: 20px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  box-shadow: none;
  margin: 20px;

  @media (max-width: 600px) {
    max-width: 90%; /* Reduce the width on mobile screens */
    padding: 10px;
    margin: 10px; /* Ensure margin to prevent the form from touching the edges */
  }
`;

const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const CustomTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
`;

const LoginButton = styled(Button)`
  background: linear-gradient(to right, #2b5876, #4e4376);
  color: white;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;

  &:hover {
    background: linear-gradient(to left, #2b5876, #4e4376);
  }

  @media (max-width: 600px) {
    padding: 8px;
    margin-top: 10px;
  }
`;

const SignupLink = styled(Link)`
  margin-top: 20px;
  display: block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API}/auth/login`, formData)
      .then(response => {
        localStorage.setItem('token', response.data.token);
        setMessage('Login successful.');
        navigate('/home');
      })
      .catch(error => {
        setMessage('Failed to login.');
      });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
      <LoginWrapper>
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
          User Login
        </Typography>
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <IconWrapper>
            <AccountCircleIcon sx={{ color: '#2b5876', marginRight: '10px' }} />
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </IconWrapper>
          <IconWrapper>
            <LockIcon sx={{ color: '#2b5876', marginRight: '10px' }} />
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </IconWrapper>
          <Box display="flex" justifyContent="space-between" alignItems="center">
          </Box>
          <LoginButton fullWidth variant="contained" type="submit">
            LOGIN
          </LoginButton>
          {/* <SignupLink variant="body2" onClick={() => navigate('/signup')}>
            Don't have an account? Sign Up
          </SignupLink>
          <Typography variant="body2" sx={{ marginTop: '20px', cursor: 'pointer' }} onClick={() => navigate('/admin-login')}>
            If you are an admin, <Link component="button" variant="body2">click here</Link> to login.
          </Typography> */}
        </Box>
        {message && <Typography variant="body2" color="error">{message}</Typography>}
      </LoginWrapper>
    </Grid>
  );
}

export default Login;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Typography, Box, Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';

const HomePageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
`;

const gradientAnimation = `
  @keyframes gradientBackground {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const ContentWrapper = styled('div')`
  ${gradientAnimation}
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #91eac9);
  background-size: 800% 800%;
  animation: gradientBackground 15s ease infinite;
  color: white;
  padding: 100px 20px 60px;
  text-align: center;
  width: 100%;
  margin: 0;
  min-height: 80vh;
  @media (max-width: 600px) {
    padding: 60px 10px 40px;
  }
`;

const StepsSection = styled(Paper)`
  background-color: #121212;
  color: #ffffff;
  padding: 60px 20px;
  margin: 40px 0;
  border-radius: 8px;
  width: 100%;
  align-self: center;
  text-align: center;
  @media (max-width: 600px) {
    padding: 40px 10px;
  }
`;

const StepItem = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 10px;
`;

const StepNumber = styled(Box)`
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 20px;
`;

const InfoSection = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #121212;
  color: white;
  padding: 60px 20px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
`;

const FAQSection = styled(Paper)`
  background-color: #ffffff;
  color: #000000;
  padding: 40px;
  margin: 40px 20px;
  border-radius: 8px;
  width: calc(100% - 40px); /* Adjusted width */
  max-width: 1200px;
  align-self: center;
  @media (max-width: 600px) {
    padding: 20px;
    margin: 40px 10px; /* Increased margin for spacing */
  }
`;

const CustomButton = styled(Button)`
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:first-child {
    background-color: #007bff;
    color: #ffffff;

    &:hover {
      background-color: #0056b3;
    }
  }

  &:last-child {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;

    &:hover {
      background-color: #f2f2f2;
      color: #000000;
      border: 1px solid #333333;
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 10px 20px;
  }
`;

function HomePage() {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/pricing');
  };

  const handleStartUploading = () => {
    navigate('/upload');
  };

  return (
    <HomePageWrapper>
      <Navbar />
      <ContentWrapper>
        <Typography variant="h1" sx={{ fontSize: { xs: '32px', md: '40px' }, marginBottom: '20px' }}>
          Discover Your Profile Secrets: Upload, Ask, and Explore!
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: '16px', md: '18px' }, marginBottom: '40px' }}>
          Effortlessly upload your profiles and get instant answers to all your questions.
        </Typography>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap="20px">
          <CustomButton onClick={handleGetStarted}>Get Started</CustomButton>
        </Box>
      </ContentWrapper>
      <StepsSection elevation={3}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <StepItem>
              <StepNumber>1</StepNumber>
              <Typography variant="h6">Upload a manual</Typography>
              <Typography>
                Upload any manual (.pdf, .doc, .docx, .txt).
              </Typography>
            </StepItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <StepItem>
              <StepNumber>2</StepNumber>
              <Typography variant="h6">Ask a question</Typography>
              <Typography>
                Ask any question about a manual you would want to explore from the manuals you uploaded.
              </Typography>
            </StepItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <StepItem>
              <StepNumber>3</StepNumber>
              <Typography variant="h6">Explore answers</Typography>
              <Typography>
                Get an answer based on the content of your manuals and enhance your knowledge.
              </Typography>
            </StepItem>
          </Grid>
        </Grid>
      </StepsSection>
      <FAQSection elevation={3}>
        <Typography variant="h4" sx={{ marginBottom: '20px', textAlign: 'center' }}>
          Frequently Asked Questions
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">What is MCM AI?</Typography>
            <Typography>
              MCM AI helps you find, explore, and ask questions about the profile and universities using AI.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Can I get answers to my manuals questions?</Typography>
            <Typography>
              Yes, MCM AI will give you answers based on the top 10 most relevant papers for your query. You can also upload and ask questions about your own PDF documents.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Do I need to be technical to use MCM?</Typography>
            <Typography>
             MCM is designed to be easy to use. You don't need to be technical to use it.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Can MCM help me write my research paper?</Typography>
            <Typography>
              Yes, MCM AI will help you write your research paper faster with AI autocomplete, paraphrase, outline generation, and more.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Do you use reputable databases and sources?</Typography>
            <Typography>
              Yes, we use reputable sources and databases for MCM AI.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">How do I get started with MCM AI?</Typography>
            <Typography>
              You can create a free account and start using MCM AI right away.
            </Typography>
          </Grid>
        </Grid>
      </FAQSection>
      <InfoSection>
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: { xs: '20px', md: '0' } }}>
          Accelerate your knowledge today with MCM AI
        </Typography>
        <CustomButton onClick={handleStartUploading}>
          Start Uploading
        </CustomButton>
      </InfoSection>
      <Footer />
    </HomePageWrapper>
  );
}

export default HomePage;
